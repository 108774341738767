import Vue from 'vue'
import { get } from 'lodash-es'
import { parseISO, format, parse } from 'date-fns'
import { utcToZonedTime } from 'date-fns-tz'
import { fr } from 'date-fns/locale'

export default Vue.extend({
  computed: {
    locale () {
      return get(this, '$i18n.locale') ? get(this, '$i18n.locale') : 'fr'
    }
  },
  methods: {
    $monthDate (value: string) {
      if (value === undefined || value === null) return ''

      return format(parseISO(value), 'LLL yy', {
        locale: this.locale === 'fr' ? fr : undefined
      })
    },
    $weekDate (value: string) {
      if (value === undefined || value === null) return ''

      return format(parseISO(value), 'dd LLL yy', {
        locale: this.locale === 'fr' ? fr : undefined
      })
    },
    $longUTCDate (date: string) {
      if (date === undefined || date === null) return ''

      const isoDate = new Date(date).toISOString()
      const dateTime = utcToZonedTime(parseISO(isoDate), 'UTC')

      return format(dateTime, 'P', {
        locale: this.locale === 'fr' ? fr : undefined
      })
    },
    $longDate (value: string) {
      if (value === undefined || value === null) return ''

      return format(parseISO(value), 'PPP', {
        locale: this.locale === 'fr' ? fr : undefined
      })
    },
    $longDateTime (value: string) {
      if (value === undefined || value === null) return ''

      return format(parseISO(value), 'PPpp', {
        locale: this.locale === 'fr' ? fr : undefined
      })
    },
    $shortDate (value: string) {
      if (value === undefined || value === null) return ''

      return format(parseISO(value), 'PP', {
        locale: this.locale === 'fr' ? fr : undefined
      })
    },
    $shortDateTime (value: string) {
      if (value === undefined || value === null) return ''

      return format(parseISO(value), 'Pp', {
        locale: this.locale === 'fr' ? fr : undefined
      })
    },
    $isoDate (date: string) {
      if (date === undefined || date === null) return ''

      return format(parseISO(date), 'yyyy-MM-dd')
    },
    $parsedDate (date: string) {
      const localFormat = this.locale === 'fr' ? 'dd/MM/yyyy' : 'MM/dd/yyyy'
      return parse(date, localFormat, new Date())
    }
  }
})
